import React, { useEffect } from "react"
import {useDispatch, useSelector} from "react-redux";

// Components
import Content from "./Content"
import TabsCustom from "src/components/TabsCustom"
import ToolBarCustom from "src/components/ToolBarCustom"

import STORAGE, { getStorage, } from "src/lib/storage"
import {setListProvince, setListPersonnel} from "src/redux/hr";
import CRMService from "src/services/CRMService";

// Styled
import { StyleTabsCustom } from "./common/styled"

export const INTENDED_USE = {
  0: { id: 0, title: 'Không kinh doanh' },
  1: { id: 1, title: 'Không kinh doanh' },
  3: { id: 1, title: 'Có kinh doanh' }
}

const Contact = () => {
  const permissions = localStorage.getItem(STORAGE.PERMISSIONS) || '';
  const role = localStorage.getItem(STORAGE.ROLES);

  const dispatch = useDispatch();
  const { listProvince, listPersonnel } = useSelector((state) => state.hr);

    const Tabs = {};

    if (permissions.includes("contact.list")) {
        Tabs.all = {
            type: "all",
            name: role === 'ADMIN_ROLE' ? "Tất cả" : 'Liên hệ của tôi',
            status: 0,
        };
    }

    if (permissions.includes("contact.listNotAssign")) {
        Tabs.notAssign = {
            type: "not_assigned",
            name: "Chưa assign",
            status: 1,
        };
    }

    if (permissions.includes("contact.listAssign")) {
        Tabs.assigned = {
            type: "assigned",
            name: "Đã assign",
            status: 2,
        };
    }

    if (permissions.includes("contact.listTaiTuc")) {
        Tabs.renew = {
            type: "renewal",
            name: "Tái tục",
            status: 3,
        };
    }
    if (permissions.includes("contact.archive")) {
        Tabs.archive = {
            type: "archive",
            name: "Kho",
            status: 'archive',
        };
    }


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Province
        if (listProvince.length === 0) {
          const provinceRes = await CRMService.getProvince();
          dispatch(setListProvince(provinceRes?.success || []));
        }

        // Fetch Personnel
        if (listPersonnel.length === 0) {
          const personnelRes = await CRMService.getListPersonnel();
          dispatch(setListPersonnel(personnelRes.data || []));
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };

    fetchData().then()
  }, [])

  const items = Object.keys(Tabs).map(key => ({
      key,
      label: <div className="text-tile-tabs">{Tabs[key].name}</div>,
      children: (
        <Content
          status={Tabs[key].status}
          type={Tabs[key].type}
        />
      ),
  }))

  return (
    <>
      <ToolBarCustom title={'Danh sách liên hệ'} status={false} />
      <StyleTabsCustom>
        <TabsCustom
          items={items}
          defaultActiveKey="all"
          type="card"
          destroyInactiveTabPane
        />
      </StyleTabsCustom>
    </>
  )
}
export default Contact

