import React, { useEffect, useRef, useState } from "react"
import {DatePicker, Input, Select, Tag, Tooltip} from "antd"
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs"

// Components
import SvgIcon from "src/components/SvgIcon"
import IconBase from "src/components/IconBase"
import PopupAction from "src/components/PopupAction"
import TableCustom from "src/components/Table/CustomTable"
import ToolBarCustomLayer from "src/components/ToolBarCustom/ToolBarCustomLayer"
import ContactCustomer, { statusCall } from "./components/ContactCustomer"
import CustomModal from "src/components/Modal/CustomModal"
import ModalAssigner from "./modal/ModalAssigner"
import ImportModal from "./modal/ExportModal"
import Notice from "src/components/Notice";

// Utils
import STORAGE, { getStorage } from "src/lib/storage"
import {fetchContactList} from "src/redux/crm";

// Styled
import { WrapperStyle, SelectStyle } from "./common/styled"
import { PaginationStyle } from "../../HumanResource/styled"
import { InputSearchStyle } from "../customer/common/styled"
import {
  MainTableData,
  MainTableHeader,
  SubTableData,
  SubTableHeader
} from "src/components/Table/CustomTable/styled"
import CB1 from "../../../../components/Modal/CB1";
import HRService from "../../../../services/HumanResource";
import notice from "../../../../components/Notice";

const headerCell = () => ({
  style: { minWidth: 80 },
})

function Content(props) {
  const { status, type } = props

  const dispatch = useDispatch()
  const permissions = localStorage.getItem(STORAGE.PERMISSIONS) || ''; // Sau sẽ lưu/sử dụng từ redux sau khi tách riêng api
  const role = localStorage.getItem(STORAGE.ROLES) || '';

  const [isComponentVisible, setIsComponentVisible] = useState(false) // phục vụ kịch bản call

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [filter, setFilter] = useState({})

  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalAssignerVisible, setIsModalAssignerVisible] = useState(false);
  const [isModalAssignerImport, setIsModalAssignerImport] = useState(false);
  const [isModalContactCustomer, setIsModalContactCustomer] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { listProvince, listPersonnel } = useSelector((state) => state.hr)
  const { dataContact, statistical, total, loading } = useSelector((state) => state.crm)

  const inputSearchRef = useRef()

  const onSearch = () => {
    setSearchTerm(inputSearchRef.current);
  }

  const getListContact = () => {
    const payload = {
      keyword: searchTerm,
      page: currentPage,
      per_page: perPage,
      filter: filter,
      // statusAssigned: status,
      type
    }

    if (type === 'all') delete payload.type
    if (!searchTerm) delete payload.keyword
    if (Object.keys(filter).length === 0) delete payload.filter
    dispatch(fetchContactList(payload))
  }

  useEffect(() => {
    getListContact();
  }, [currentPage, perPage, status, type, searchTerm, filter]);

  const column = [
    {
      title: `Mã LH`,
      dataIndex: "code_contact",
      key: "code_contact",
      align: "center",
      onHeaderCell: () => ({
        style: { minWidth: 60 },
      }),
    },
    {
      title: `Tên KH`,
      dataIndex: "contact_name",
      key: "contact_name",
      align: "center",
      onHeaderCell: () => ({
        style: { minWidth: 120 },
      }),
    },
    ...type === 'renewal' ? [
      {
        title: `Giá tiền đã bán`,
        dataIndex: "price",
        key: "price",
        align: "center",
        onHeaderCell: () => ({
          style: { minWidth: 120 },
        }),
      },
      {
        title: `Địa chỉ nhận hàng`,
        dataIndex: "shipping_address",
        key: "shipping_address",
        align: "center",
        onHeaderCell: () => ({
          style: { minWidth: 150 },
        }),
      },
    ] : [],
    {
      title: "Thời hạn bảo hiểm",
      dataIndex: "expiry_date",
      key: "expiry_date",
      align: "center",
      sorter: (a, b) => {
        const dateA = new Date(a.expiry_date.split('/').reverse().join('-')); // Chuyển đổi thành "YYYY-MM-DD"
        const dateB = new Date(b.expiry_date.split('/').reverse().join('-')); // Chuyển đổi thành "YYYY-MM-DD"
        return dateA - dateB;
      },
      onHeaderCell: () => ({
        style: { minWidth: 90 },
      }),
    },
    {
      title: "Ngày xóa",
      dataIndex: "updated_at",
      key: "updated_at",
      align: "center",
      onHeaderCell: headerCell,
    },
    {
      title: "Biển kiểm soát",
      dataIndex: "plate",
      key: "plate",
      align: "center",
      onHeaderCell: () => ({
        style: { minWidth: 110 },
      }),
    },
    {
      title: "Nhãn hiệu",
      dataIndex: "vehicles_brand",
      key: "vehicles_brand",
      align: "center",
      onHeaderCell: headerCell,
    },
    {
      title: "Loại xe",
      dataIndex: "vehicles_type",
      key: "vehicles_type",
      align: "center",
      onHeaderCell: headerCell,
    },
    {
      title: (
        <>
          <MainTableHeader>Trọng tải</MainTableHeader>
          <SubTableHeader>Số chỗ</SubTableHeader>
        </>
      ),
      dataIndex: "vehicles_tonnage",
      key: "vehicles_tonnage",
      align: "center",
      render: (val, record) => (
        <>
          {record?.vehicles_tonnage && (
            <MainTableData>{`Trọng tải: ${record?.vehicles_tonnage}`}</MainTableData>
          )}
          {record?.vehicles_seat_capacity && (
            <SubTableData>{`Số chỗ: ${record?.vehicles_seat_capacity}`}</SubTableData>
          )}
        </>
      ),
      onHeaderCell: () => ({
        style: { minWidth: 110 },
      }),
    },
    {
      title: "Mục đích sử dụng",
      dataIndex: "intended_use",
      key: "intended_use",
      align: "center",
      onHeaderCell: () => ({
        style: { minWidth: 130 },
      }),
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      align: "center",
      onHeaderCell: headerCell,
    },
    {
      title: "Assign",
      dataIndex: "assign",
      key: "assign",
      align: "center",
      onHeaderCell: headerCell,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: val => {
        const objStatus = statusCall.filter(item => item.id === val)[0] || {}
        return <span>{objStatus.name}</span>
      },
      onHeaderCell: headerCell,
    },
    {
      title: "Ghi chú",
      dataIndex: "desc",
      key: "desc",
      align: "center",
      onHeaderCell: headerCell,
    },
    // {
    //   title: "Ngày tạo",
    //   dataIndex: "date_create",
    //   key: "date_create",
    //   align: "center",
    //   onHeaderCell: headerCell,
    // },
    {
      title: <Tooltip title={"Hành động"}>_</Tooltip>,
      dataIndex: "action",
      key: "action",
      align: "center",
      onHeaderCell: () => ({
        style: { minWidth: 50 },
      }),
      onCell: () => ({
        onClick: event => event.stopPropagation(),
      }),
      render: (_, record) => {
        return (
            <PopupAction>
              <div
                  onClick={() => {
                    if (!permissions.includes("contact.listAssign")) {
                      Notice({
                        isSuccess: false,
                        msg: "Bạn chưa có quyền truy cập tính năng này!",
                      })
                      return
                    }
                    setIsModalAssignerVisible(record.code_contact)
                  }}
              >
                <IconBase name="addUser"/>
                <span>Assigner</span>
              </div>
              {
                status !== 'archive' ? (
                    <div onClick={() => {
                      if (!permissions.includes("contact.delete")) {
                        Notice({
                          isSuccess: false,
                          msg: "Bạn không có quyền thực hiện hành động này!",
                        })
                        return
                      }
                      CB1({
                        title: `Bạn có chắc chắn muốn đơn hàng này không?`,
                        icon: "trashRed",
                        okText: "Đồng ý",
                        onOk: async close => {
                          HRService.deleteContactList({ids: [record.code_contact]})
                              .then(() => {
                                Notice({
                                  isSuccess: true,
                                  msg: "Xóa đơn hàng thành công",
                                })
                                getListContact()
                              })
                              .catch(err => {
                                notice({
                                  isSuccess: false,
                                  msg: "Xóa đơn hàng thất bại",
                                })
                              })
                        },
                      })
                    }}>
                      <IconBase name="delete"/>
                      <span>Xóa</span>
                    </div>
                  ) : <React.Fragment />}
            </PopupAction>
        )
      },
    },
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys)
    },
  };

  const itemsLeft = [
    {
      key: "create_person_charge",
      title: "Assigner",
      disable: selectedRowKeys.length === 0,
      onClick: () => {
        if (!permissions.includes("contact.listAssign")) {
          Notice({
            isSuccess: false,
            msg: "Bạn chưa có quyền truy cập tính năng này!",
          })
          return
        }
        setIsModalAssignerVisible(true)
      },
    },
    {
      key: "drop_down",
      component: (
          <SelectStyle
              onChange={(value) => {
                if (value === undefined || value === "") {
                  const newFilter = {...filter};
                  delete newFilter.province;
                  setFilter(newFilter);
                  return
                }
                setFilter((prevState) => ({...prevState, province: value}))
              }}
              mode="multiple"
              showSearch
              placeholder="Chọn tỉnh thành"
              optionLabelProp="label"
          style={{ maxWidth: '200px', width: '150px' }}
          allowClear
          filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
          }
          >
            {listProvince.map(option => (
                <Select.Option key={option.title} value={option.plate_code} label={option.title}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <span>{option.title}</span>
                    <Tag bordered={false} color="blue">
                      {option?.plate_code}
                    </Tag>
                  </div>
                </Select.Option>
            ))}

          </SelectStyle>
      ),
    },
    // ...(storedUserInfo !== "no" ? [
    {
      key: "drop_down",
      component: (
          <SelectStyle
              placeholder="Assigner"
              allowClear
                style={{ width: '170px', height: '37px' }}
                showSearch
                optionFilterProp="label"
                onChange={(value) => {
                  if (value === undefined || value === "") {
                    const newFilter = { ...filter };
                    delete newFilter.personnel_id;
                    setFilter(newFilter);
                    return;
                  }
                  setFilter(prevState => ({ ...prevState, personnel_id: value }));
                }}
                options={listPersonnel.map(item => ({ value: item.ID, label: item.name }))}
                filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                }
            />
        ),
      },
    // ] : []),
    {
      key: "drop_down",
      component: (
        <SelectStyle
          onChange={(value ) => {
            if (value === undefined || value === "") {
              const newFilter = { ...filter };
              delete newFilter.status;
              setFilter(newFilter);
              return
            }
            setFilter((prevState) => ({...prevState, status: value}))
          }}
          showSearch
          placeholder="Chọn trạng thái"
          optionLabelProp="label"
          style={{ maxWidth: '200px', width: '150px', height: '37px' }}
          allowClear
          options={statusCall.map(item => ({ value: item.id, label: item.name }))}
          filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
          }
        />
      )
    },
    {
      key: "date_picker",
      component: (
          <DatePicker.RangePicker
              format="DD/MM/YYYY"
              style={{width: '230px'}}
              placeholder={['Bắt đầu BH', 'Kết thúc BH']}
              onChange={(dates) => {
                if (dates === null) {
                  const newFilter = {...filter}
                  delete newFilter.start_date
                  delete newFilter.end_date
                  setFilter(newFilter)
                  return
                }
                const start_date = dayjs(dates[0]).format('YYYY-MM-DD');
                const end_date = dayjs(dates[1]).format('YYYY-MM-DD');
                setFilter(preVState => ({ ...preVState, start_date, end_date }))
              }}
          />
      ),
    },
    {
      key: "date_picker",
      component: (
          <DatePicker.RangePicker
              format="DD/MM/YYYY"
              style={{width: '230px'}}
              placeholder={['Ngày gọi', 'Ngày gọi']}
              onChange={(dates) => {
                if (dates === null) {
                  const newFilter = {...filter}
                  delete newFilter.start_updated
                  delete newFilter.end_updated
                  setFilter(newFilter)
                  return
                }
                const start_updated = dayjs(dates[0]).format('YYYY-MM-DD');
                const end_updated = dayjs(dates[1]).format('YYYY-MM-DD');
                setFilter(preVState => ({ ...preVState, start_updated, end_updated }))
              }}
          />
      ),
    },
  ]

  const itemsRight = [
    {
      key: "input_search",
      component: (
        <InputSearchStyle>
          <Input
            className="custom-input"
            maxLength={50}
            placeholder="Biển KS, Địa chỉ, Loại xe ..."
            onChange={e => (inputSearchRef.current = e.currentTarget.value)}
            onPressEnter={onSearch}
          />
          <div className="custom-icon-search">
            <SvgIcon name="searchStatistic" onClick={onSearch} />
          </div>
        </InputSearchStyle>
      ),
    },
    ...(permissions.includes('contact.import') && status === 0 ? [
      {
        key: "import_excel",
        title: "Tải file lên",
        disable: false,
        onClick: () => { setIsModalAssignerImport(true) },
      }
    ]: []),
  ];
  const handleCloseModal = () => {
    setIsModalAssignerVisible(false);
  };

  const handleCloseModalImport = () => {
    setIsModalAssignerImport(false);
  };
  const handleImportSuccess = () => {
    handleCloseModal();
    getListContact()
  };

  return (
      <WrapperStyle>
        <ToolBarCustomLayer itemsLeft={itemsLeft} itemsRight={itemsRight}/>
        <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0' }}>
          <div style={{ padding: '0 5px', color: '#007BFF' }}><strong>Tổng liên hệ:</strong> {total}</div>
          <div style={{ padding: '0 5px', color: '#28A745'}}><strong>Trạng thái mới:</strong> {statistical?.status_new || 0}</div>
          <div style={{ padding: '0 5px', color: '#DC3545' }}><strong>Trạng thái còn lại:</strong> {statistical?.status_call || 0}</div>
        </div>
        <TableCustom
            noMrb
            isPrimary
            columns={column}
            onRow={(record) => ({
              onClick: (event) => {
                if (
                    !event.target.closest(".ant-checkbox-wrapper") && // Không phải checkbox
                    !event.target.closest("td.ant-table-selection-column") // Không phải cột select
                ) {
                  if (!permissions.includes("contact.detail")) {
                    Notice({
                      isSuccess: false,
                      msg: "Bạn chưa có quyền truy cập tính năng này!",
                    })
                    return;
                  }
                  setSelectedContactId(record?.code_contact);
                  setIsModalContactCustomer(true);
                }
              }
            })}
            dataSource={dataContact}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            loading={loading}
            pagination={{
              current: currentPage,
              pageSize: perPage,
              total: total,
            }}
            rowKey={record => record.code_contact}
            footerLeft={<div className="d-flex mt-20"/>}
            widthScroll={1200}
            textEmpty="Không có dữ liệu!"
        />
        <PaginationStyle
            pageSizeOptions={['50', '100', '150', '200']}
            current={currentPage}
            pageSize={perPage}
            defaultPageSize={50}
            showSizeChanger
            onShowSizeChange={(page, pageSize) => {
              setPerPage(pageSize);
              setCurrentPage(page);
            }}
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setPerPage(pageSize);
            }}
            defaultCurrent={1}
            total={total}
            itemRender={(page, type, originalElement) => {
              if (type === 'page') {
                return (
                    <span
                        style={{
                          fontWeight: page === currentPage ? 'bold' : 'normal',
                          color: page === currentPage ? '#1890ff' : 'inherit', // Hiển thị màu khi trang hiện tại đang active
                        }}
                    >
          {page}
        </span>
                );
              }
              return originalElement;
            }}
        />
        {isModalAssignerVisible && (
            <CustomModal
                title={"Assigner"}
                footer={null}
                size={'small'}
                open={isModalAssignerVisible}
                onCancel={handleCloseModal}
            >
              <ModalAssigner
                  status={status}
                  dataSelected={selectedRowKeys}
                  getData={getListContact}
                  open={[isModalAssignerVisible]}
                  onCancel={handleCloseModal}
                  setSelectedRowKeys={setSelectedRowKeys}
              />
            </CustomModal>
        )}
        {isModalAssignerImport && (
            <CustomModal
                title={"Tải file lên"}
                footer={null}
                size={'small'}
                open={isModalAssignerImport}
                onCancel={handleCloseModalImport}
            >
              <ImportModal
                  open={isModalAssignerImport}
                  onCancel={handleCloseModalImport}
                  onOk={handleImportSuccess}
              />
            </CustomModal>
        )}
        {isModalContactCustomer && (
            <CustomModal
                title={'Liên hệ khách hàng'}
                open={isModalContactCustomer}
                onCancel={() => !isComponentVisible && setIsModalContactCustomer(false)}
                footer={null}
                size='large'
            >
              <ContactCustomer
                  isComponentVisible={isComponentVisible}
                  setIsComponentVisible={setIsComponentVisible}
                  getListContact={getListContact}
                  contactId={selectedContactId}
                  setIsModal={setIsModalContactCustomer}
                  onCancel={() => setIsModalContactCustomer(false)}
                  type={type}
              />
            </CustomModal>
        )}
      </WrapperStyle>
  )
}

export default Content
