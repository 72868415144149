import React, { useState } from 'react';
import { Row, Col, Spin, message, Upload } from 'antd';
import { ImportStyled } from './styled';
import SvgIcon from 'src/components/SvgIcon';
import HRService from 'src/services/HumanResource';

const { Dragger } = Upload;

const ImportModal = ({ open, onCancel, onOk }) => {
  const [loading, setLoading] = useState(false);

  // Xử lý upload file thành côngc
  const handleUpload = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', file); // Thêm file vào formData

      // Duyệt qua các phần tử của formData để xem nội dung
      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}:`, value);
      // }
      // Gọi API upload file
      const response = await HRService.getImport(formData);

      // Kiểm tra phản hồi từ API
      if (response?.isError) {
        message.error('Upload thất bại!');
      } else {
        message.success('Upload thành công!');
        onCancel()
        onOk(); // Gọi callback nếu upload thành công
      }
    } catch (error) {
      message.error(error?.response?.data?.message || 'Có lỗi xảy ra khi upload!');
    } finally {
      setLoading(false);
    }
  };

  // Cấu hình Upload props
  const uploadProps = {
    name: 'file',
    multiple: false, // Cho phép upload 1 file tại một thời điểm
    accept: '.xlsx, .xls, .xlsm', // Chỉ chấp nhận các file Excel
    customRequest({ file, onSuccess, onError }) {
      // console.log("file", file);
      // Sử dụng customRequest để upload file qua API
      handleUpload(file)
        .then(() => onSuccess("ok")) // Xử lý thành công
        .catch((err) => onError(err)); // Xử lý lỗi
    },
    showUploadList: false, // Ẩn danh sách file đã upload
  };

  return (
    <ImportStyled>
      <Spin spinning={loading}>
        <div style={{ marginBottom: 10 }}>
          Tải file mẫu{" "}
          <span
            onClick={() => {}}
            style={{ color: "#154398", cursor: "pointer" }}
          >
            Tại đây
          </span>
        </div>

        <Dragger {...uploadProps}>
          <Row gutter={20} className="justify-content-center">
            <Col>
              <SvgIcon name="cloud" />
            </Col>
            <Col>
              <span>
                Kéo thả file đính kèm hoặc{" "}
                <span style={{ color: "#154398" }}>Chọn File</span>
              </span>
            </Col>
          </Row>
        </Dragger>

        <Row className="box-note pt-15">
          <Col>
            <SvgIcon name="warningCKS" />
          </Col>
          <Col className="ml-10">
            Lưu ý: Tải lên file.
          </Col>
        </Row>
      </Spin>
    </ImportStyled>
  );
};

export default ImportModal;
